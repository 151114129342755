<script setup lang="ts">
const { windowSize }: any = useWindowSize()
const emit = defineEmits()
const route = useRoute()
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: () => false
  }
})

const isActive = ref(false)
const NowX = ref(0)
const NowY = ref(0)
const NowUpdatedX: any = ref(0)
const NowUpdatedY: any = ref(0)
const elParent: any = ref(null)
const InitSidebar = () => {
  NowX.value = 0
  NowY.value = 0
  NowUpdatedX.value = 0
  NowUpdatedY.value = 0
  isActive.value = false
  // if (windowSize.value.width >= 1024) {
  //   return false
  // }
  const el: any = document.querySelector('.scroll-snap')
  if (el) {
    elParent.value = el.parentElement
    elParent.value.addEventListener('touchmove', (e: any) => {
      if (route.name === 'maps' || route.name === 'channel-channelid-nav-projectid') {
        return false
      }
      const x = e.touches[0].clientX
      const y = e.touches[0].clientY
      if (!NowY.value) {
        NowY.value = y
      }
      NowUpdatedY.value = y - NowY.value
      if (NowUpdatedY.value > 20 || NowUpdatedY.value < -20) {
        return false
      }
      if (!NowX.value) {
        NowX.value = x
      }
      NowUpdatedX.value = x - NowX.value
    })
    elParent.value.addEventListener('touchend', () => {
      NowX.value = 0
      NowY.value = 0
      if (NowUpdatedX.value >= 50) {
        isActive.value = true
      } else {
        if (!isActive.value) {
          NowUpdatedX.value = 0
          NowUpdatedY.value = 0
        }
        isActive.value = false
      }
    })
  }
}

const InitSidebarClear = () => {
  if (elParent.value) {
    elParent.value.removeEventListener('touchmove', () => {})
    elParent.value.removeEventListener('touchend', () => {})
    window.removeEventListener('resize', () => {})
  }
}

watch(() => isActive.value, (a: any) => {
  NowUpdatedX.value = 0
  if (a) {
    NowUpdatedX.value = 300
    emit('update:modelValue', true)
  } else {
    emit('update:modelValue', false)
    NowUpdatedX.value = 0
  }
})
watch(() => props.modelValue, (a: any) => {
  isActive.value = a
}, { immediate: true })
watch(() => windowSize.value, () => {
  InitSidebarClear()
  InitSidebar()
}, { deep: true })
onActivated(() => {
  InitSidebar()
})
onMounted(() => {
  InitSidebar()
})
onDeactivated(() => {
  InitSidebarClear()
})

const TransformCounter = () => {
  if (NowUpdatedX.value > 300) {
    return -300 + 'px'
  } else if (NowUpdatedX.value <= 0) {
    return 0 + 'px'
  } else {
    return parseInt((-NowUpdatedX.value || 0) + '').toFixed(0) + 'px'
  }
}

const onResize = () => {
  console.log('resized')
}
</script>
<template>
  <div class="scroll-snap relative w-full" :class="[isActive ? 'warloc-sidebar-active' : '']" @click.prevent="isActive = false" @resize="onResize">
    <div class="scroll-snap-container" :style="{ transform: (NowUpdatedX ? `translateX(calc(-100% - ${TransformCounter()}))` : 'translateX(-300px)'), width: (NowUpdatedX ? '300px' : '300px') }" @click.stop>
      <div class="warloc-sidebar relative w-full h-full overflow-y-auto bg-white scrollbar-mini">
        <slot />
      </div>
    </div>
    <div v-if="parseInt($route.query?.chat)" :class="[isActive ? 'show' : '']" class="chat-container fixed top-0 right-0 bg-gray-100 shadow-inner h-full z-[1000000]" @click.stop>
      <div class="w-full h-[60px] bg-gray-50 font-bold flex items-center px-4">
        Chatroom #123
      </div>
      <div class="chat-container-inner flex flex-col-reverse overflow-auto pb-2 bg-gray-100" style="height: calc(100% - 105px);overflow-anchor: auto !important;">
        <div v-for="(m) in 50" class="w-100% max-w-[475px]" style="transform: translateZ(0);">
          <div class="px-4">
            <div class="flex items-end py-2">
              <span class="w-[36px] h-[36px] rounded-full shadow" :class="m % 3 === 0 ? 'bg-gray-300' : 'bg-indigo-200'"></span>
              <div class="pl-2" style="width: calc(100% - 36px);">
                <div class="chat-text w-full px-3 py-2 rounded-xl text-sm shadow-sm" :class="m % 3 === 0 ? 'bg-gray-200' : 'bg-indigo-100'">
                  Siap Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries {{ m }} ..
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="relative w-full h-[45px] bg-gray-50 flex items-center">
        <div
          contenteditable="true"
          class="absolute bottom-0 left-0 py-3 outline-none bg-gray-100 border-t border-gray-200 min-h-full w-full px-4 overflow-y-auto leading-4 text-sm flex flex-col justify-center"
          spellcheck="false"
        >
          Write message ...
        </div>
      </div>
    </div>
    <div v-else class="chat-container fixed flex items-center justify-center top-0 right-0 bg-gray-400 shadow-inner h-full z-[1000000]" @click.stop>
      <div class="text-gray-400 uppercase tracking-widest">
        - NO CHAT SELECTED -
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.scroll-snap {
  >.chat-container {
    >.chat-container-inner {
      scroll-behavior: smooth;
    }
    width: calc(100% - 300px);
    transition: all .3s ease-in-out;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    &.show {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }
    .chat-text {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        bottom: 0px;
        z-index: -1;
        left: -10px;
        right: auto;
        border: 12px solid;
        transform:rotate(180deg);
        border-color: rgb(229,231,235) transparent transparent rgb(229,231,235);
      }
    }
  }
  >.scroll-snap-container {
    position: fixed;
    top:0;
    left:0px;
    transform: translateX(-300px);
    width: 300px;
    height: 100dvh;
    z-index: 1000002;
    white-space: nowrap;
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    align-content: stretch;
    overflow-y: visible;
    transition: all .2s cubic-bezier(0.4, 0, 0.2, 1);
    .warloc-sidebar {
      z-index: 1000001;
    }
  }
  &::before {
    content: "";
    position: fixed;
    transform: initial;
    top: 0;
    left: 0;
    width: 100dvw;
    height: 100dvh;
    z-index: 999999;
    transition: all .3s ease-in-out;
    background-color: rgba(0, 0, 0, .3);
    opacity: 0;
    pointer-events: none;
  }
  &.warloc-sidebar-active {
    &::before {
      pointer-events: all;
      opacity: 1;
    }
  }
}
</style>