export const useSplash = () => {
  const splash = useState<any>('splash', () => true)
  const setSplash = (t: any) => {
    splash.value = t || false
  }

  return {
    splash,
    setSplash
  }
}
