<script setup lang="ts">
// https://github.com/infinitered/nsfwjs IMAGE SENSOR
const route: any = useRoute()
const { $parseHTML, $TextLink, $convertImage, $blobToBase64, $dataURItoBlob, $ImagesOne, $FileParse }: any = useNuxtApp()
const {
  handleKeyboardShortcut
}: any = useChatFormatter()
const { auth } = useAuth()
const { channelMapSelected } = useChannel()
const { showCreatePost, timelineRepost, setTimelineRepost }: any = useTimeline()
const router: any = useRouter()
const emit = defineEmits()

const UploadFileData: any = ref(null)
const UploadFileType: any = ref(null)

const UploadFileContent: any = ref(null)
const UploadFileContentAttachments: any = ref([])
const UploadFileContentAttachmentsShow = ref(false)

if (process.client) {
  watch(() => showCreatePost.value, (v: any) => {
    if (v) {
      UploadFileType.value = null
      UploadFileContent.value = null
      UploadFileContentAttachments.value = []
      // @ts-ignore
      document.body.style = 'overflow:hidden;'
      // setTimeout(() => {
      //   const ce: any = document.querySelector('.warloc-content-area')
      //   if (ce) {
      //     ce.addEventListener('paste', function (e: any) {
      //       e.preventDefault()
      //       var text = e.clipboardData.getData('text/plain')
      //       document.execCommand('insertText', false, $parseHTML(text, ['strong', 'code', 'i', 'em', 'u', 'b', 'div']))
      //       console.log('pasted!')
      //     })
      //   }
      // }, 300)
    } else {
      // @ts-ignore
      document.body.style = ''
      // setTimeout(() => {
      //   const ce: any = document.querySelector('.warloc-content-area')
      //   if (ce) {
      //     ce.addEventListener('paste', () => {})
      //   }
      // }, 300)
    }
  }, { immediate: true })
}

const ImageEditorShow = ref(false)
const UploadFile: any = (isAttachment?: boolean) => {
  UploadFileType.value = null
  if (TimelineLoading.value) {
    return
  }
  let u = document.getElementById('file-uploader')
  if (u) {
    u.remove()
  }
  if (!isAttachment) {
    UploadFileData.value = null
  }
  setTimeout(() => {
    u = document.createElement('input')
    u.setAttribute('type', 'file')
    u.setAttribute('id', 'file-uploader')
    u.setAttribute('class', 'file-uploader')
    // u.setAttribute('multiple', 'file-uploader')
    u.setAttribute('accept', isAttachment ? '.png,.jpeg,.jpg' : '.png,.jpeg,.jpg,.mp4,.avi,.flv,.mov')
    u.addEventListener('change', async (e: any) => {
      UploadFileData.value = null
      const f = e.target.files[0]
      const a = await $FileParse(e.target.files[0])
      if (a && a.status && a.data) {
        UploadFileType.value = a.data
        if (a.data.type === 'image') {
          const result = await $convertImage(f, 512, 512)
          if (isAttachment) {
            const f = await $blobToBase64(result)
            UploadFileContentAttachments.value.push({
              type: 'image',
              src: f
            })
          } else {
            UploadFileContent.value = await $blobToBase64(result)
            UploadFileData.value = UploadFileContent.value
          }
        } else {
          UploadFileContent.value = a.data.file
        }
      }
    })
    u.click()
  }, 100)
}

const modelContent: any = ref(null)
watch(() => modelContent.value, (v: any) => {
  if (v) {
    modelContent.value = v
  } else {
    modelContent.value = null
  }
})

watch(() => route.query, (v: any) => {
  ImageEditorShow.value = false
  if (v && parseInt(v.edit) && UploadFileContent.value) {
    UploadFileData.value = UploadFileContent.value
    ImageEditorShow.value = true
  }
})

const TimelineType = ref('cover')
const TimelineColorData: any = ref({
  bg: '#fafafa',
  text: '#1F1F1F'
})

const HandlePaste = (e: any) => {
  e.preventDefault()
  var text = e.clipboardData.getData('text/plain')
  document.execCommand('insertText', false, text)
}

const SaveImage = (e: any) => {
  UploadFileContent.value = e
  UploadFileData.value = null
}

const TimelineLoading = ref(false)
const TimelinePost = async () => {
  if (TimelineLoading.value) {
    return
  }
  const file = $dataURItoBlob(UploadFileContent.value)
  const d: any = new FormData()
  d.append('file', file)
  if (UploadFileContentAttachments.value.length) {
    for (let f = 0; f < UploadFileContentAttachments.value.length; f++) {
      const ff = UploadFileContentAttachments.value[f]
      if (ff && ff.type === 'image') {
        d.append(`file`, $dataURItoBlob(ff.src))
      }
    }
  }
  d.append('file_type', UploadFileType.value?.type || '')
  d.append('file_format', UploadFileType.value?.format || '')
  d.append('content', modelContent.value)
  if (timelineRepost.value) {
    d.append('reply_to', timelineRepost.value.id)
  }
  if (route.params.channelid && channelMapSelected.value && channelMapSelected.value[route.params.channelid].creator === auth.value?.id) {
    d.append('channel', route.params.channelid)
  }
  TimelineLoading.value = true
  const req = await useApiPost('/timeline', d)
  if (req && req.status && req.data && req.data.id) {
    UploadFileData.value = null
    UploadFileContent.value = []
    UploadFileContentAttachments.value = []
    modelContent.value = null
    setTimelineRepost(null)
    emit('reload', true)
    router.go(-1)
  }
  TimelineLoading.value = false
}
</script>

<template>
  <div v-show="showCreatePost" id="timeline-create" class="fixed z-[1001] origin-bottom transition-all duration-100 top-0 left-0 h-full flex items-center w-full bg-gray-50 dark:bg-[#111111C2] md:p-4" @click.prevent="$router.back()">
    <div class="w-full md:pb-0 px-0 md:pl-2 lg:pl-4 relative h-full flex items-center">
      <div class="flex flex-col justify-start mx-auto my-auto min-h-full w-full md:w-[600px] max-w-[100%]  bg-white dark:bg-black max-h-full overflow-y-auto scroll-hidden" @click.stop>
        <div class="flex flex-col w-full overflow-auto scroll-hidden grow" style="height:calc(100% - 68px);">
          <div v-if="channelMapSelected[channelMapSelected.selected] && channelMapSelected[channelMapSelected.selected].creator === auth?.id" class="flex items-center py-4 px-4">
            <div :style="{ backgroundImage: `url(${channelMapSelected[channelMapSelected.selected].logo})` }" class="block bg-gray-200 dark:bg-gray-800 rounded-full w-7 h-7 bg-cover mr-2" />
            <span class="grow truncate pr-4 text-xs lg:text-sm text-gray-500 dark:text-gray-500">
              {{ channelMapSelected[channelMapSelected.selected].name || '#' }}
            </span>
          </div>
          <div v-else class="flex items-center py-4 px-4 w-full">
            <div v-if="auth?.img" :style="{ backgroundImage: `url(${auth?.img})` }" class="block bg-gray-200 dark:bg-gray-800 rounded-full w-7 h-7 bg-cover mr-2" />
            <span class="grow truncate pr-4 text-sm text-black dark:text-white">
              {{ auth?.name || 'No name' }}
            </span>
            <span class="grow-1" />
            <div class="flex items-center justify-between pl-4 text-gray-500 dark:text-gray-300 rounded font-serif font-bold text-sm cursor-pointer" @click.prevent="$router.go(-1)">
              <XMarkIcon class="w-7" />
            </div>
          </div>
          <div v-if="timelineRepost" class="px-4 w-full">
            <div class="flex w-full items-center mb-4 rounded bg-gray-100 dark:bg-midnight-10">
              <div v-if="$ImagesOne(timelineRepost.content_media).src" class="bg-cover-center ml-3 my-3 w-20 h-20 min-w-20 min-h-20 overlow-hidden" :style="{ backgroundImage: `url(${$ImagesOne(timelineRepost.content_media).src || ''})` }" />
              <div class="p-4 grow">
                <div class="flex items-center">
                  <div :style="{ backgroundImage: `url(${timelineRepost.creator_img})` }" class="block bg-gray-200 dark:bg-gray-800 rounded-full bg-cover h-5 w-5 lg:h-6 lg:w-6 mr-1 lg:mr-2" />
                  <span class="grow truncate pr-4 text-xs lg:text-sm text-gray-500 dark:text-gray-500">
                    {{ timelineRepost.creator_name || 'No name' }}
                  </span>
                </div>
                <div class="text-xs md:text-sm pt-2 md:pt-2 break-words w-full text-gray-400 dark:text-gray-400 line-clamp-2" v-html="$parseHTML(timelineRepost.content)" />
              </div>
            </div>
          </div>
          <div v-if="TimelineType === 'cover'" :class="[UploadFileContent ? '' : 'w-full']">
            <div v-if="UploadFileContent" class="flex justify-center w-full max-h-[calc(100dvh-290px)] overflow-y-auto scroll-hidden">
              <div class="min-w-[70%] h-auto max-w-full">
                <img v-if="UploadFileType?.type === 'image'" :src="UploadFileContent" class="block w-full">
                <video v-else-if="UploadFileType?.type === 'video'" muted loop class="block w-full object-cover sm:min-h-unset">
                  <source :src="UploadFileContent" :type="`${UploadFileType?.type}/${UploadFileType?.format}`">
                  Your browser does not support HTML5 video.
                </video>
              </div>
            </div>
            <div v-else class="px-4">
              <div class="flex items-center justify-start w-full min-h-[50px] py-4 bg-transparent rounded text-center text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 transition-all cursor-pointer" @click.prevent="UploadFile()">
                Add cover image or video
              </div>
            </div>
            <div v-if="UploadFileContent" class="flex items-center text-xs font-bold text-gray-600 bg-transparent dark:bg-midnight-10 border-b border-gray-200 dark:border-gray-800">
              <div :class="[UploadFileType?.type === 'image' ? 'w-1/2' : 'w-full']" class="flex justify-center align-center p-4 text-red-500 dark:text-red-500 cursor-pointer" @click.prevent="TimelineLoading ? false : UploadFileContent = null, UploadFileData = null">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" class="w-4 h-4 mr-2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                </svg>
                REMOVE
              </div>
              <div v-if="UploadFileType?.type === 'image'" class="flex justify-center align-center w-1/2 p-3 border-l border-gray-200 dark:border-gray-700 cursor-pointer dark:text-gray-400" @click.prevent="TimelineLoading ? false : $router.push(`?post=${route.query.post}&edit=1`)">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 mr-2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                </svg>
                EDIT
              </div>
            </div>
          </div>
          <div class="grow w-full flex self-stretch">
            <textarea
              v-model="modelContent"
              placeholder="Write here ..."
              class="items-stretch flex w-full placeholder:text-gray-400 dark:placeholder:text-gray-400 border-none scrollbar-mini outline-none px-4 py-4 text-black rounded dark:text-gray-100 bg-transparent font-normal box-border"
              autofocus
              resize="false"
              spellcheck="false"
              @keyup="($event: any) => modelContent = $event.target.value"
              @keydown="handleKeyboardShortcut"
            />
          </div>
          <div v-show="UploadFileContentAttachmentsShow" class="bg-gray-100 dark:bg-gray-900 flex items-center w-[calc(100%+1rem)] h-auto -mx-2">
            <HScroll :items="UploadFileContentAttachments" class="">
              <template #default="{ item }: any">
                <img :src="item.src" class="block h-full max-h-full max-w-full rounded-xl">
              </template>
            </HScroll>
            <div :class="[UploadFileContentAttachments.length ? 'border-l' : '']" class="h-full min-h-full flex items-center pl-5 pr-6 py-10 cursor-pointer border-gray-300 dark:border-gray-600" @click="UploadFile(true)">
              <PlusIcon class="w-6 text-gray-600 dark:text-gray-300" />
            </div>
          </div>
        </div>
        <div class="h-px bg-gray-200 dark:bg-gray-900 w-full" />
        <div class="flex w-full justify-between p-4">
          <span class="w-12 flex items-center justify-end cursor-pointer opacity-90 hover:opacity-100" @click="UploadFileContentAttachmentsShow = !UploadFileContentAttachmentsShow">
            <ChevronDownIcon v-if="UploadFileContentAttachmentsShow" class="text-gray-400 w-8" />
            <ChevronUpIcon v-else class="text-gray-400 w-8" />
          </span>
          <div
            :class="[!UploadFileContent && !modelContent ? 'from-gray-400 to-gray-500' : 'from-green-700 to-green-500']"
            class="flex items-center justify-between px-6 py-2 rounded-3xl shadow-sm font-serif bg-gradient-to-r from-green-700 to-green-500 text-white text-sm cursor-pointer"
            @click.prevent="(TimelineLoading || (!UploadFileContent && !modelContent)) ? false : TimelinePost()"
          >
            <svg v-if="TimelineLoading" class="animate-spin -ml-1 mr-3 h-4 w-4 text-gray-800" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            {{ TimelineLoading ? 'Processing ...' : (timelineRepost ? (modelContent ? 'Post Reply' : 'Just Repost') : 'Create Post') }}
          </div>
        </div>
      </div>
    </div>
    <TimelineEditorImage v-if="UploadFileData && UploadFileType?.type === 'image'" :show="ImageEditorShow" :original-image="UploadFileData" @save="SaveImage" />
  </div>
</template>

<style lang="scss">
</style>
