<script setup lang="ts">
const { $JsonRaw } = useNuxtApp()
const emit: any = defineEmits()
const router: any = useRouter()
const props: any = defineProps({
  show: {
    type: Boolean,
    default: false
  },
  originalImage: {
    default: null
  }
})

const editorActive: any = ref(1)
const imageSelected: any = ref({
  width: 0,
  height: 0
})
const showCropper = ref(false)
const OriginalImage = ref(null)
const cropperRef: any = ref(null)
// onActivated(() => {
//   console.log('sss')
// })
watch(() => props.originalImage, async (v: any) => {
  const i = new Image()
  i.onload = function(){
    imageSelected.value = i
  }
  i.src = v
  editorActive.value = 1
  showCropper.value = false
  OriginalImage.value = v
  await nextTick()
  showCropper.value = true
}, { immediate: true })

const flip = (x: any, y: any) => {
  const { image } = cropperRef.value.getResult()
  if (image.transforms.rotate % 180 !== 0) {
    cropperRef.value.flip(!x, !y)
  } else {
    cropperRef.value.flip(x, y)
  }
}

const rotate = (angle: any) => {
  cropperRef.value.rotate(angle)
}

// const download = () => {
//   const result = cropperRef.getResult().canvas.toDataURL();
//   const newTab = window.open()
// }

const ShowCropperWindow = async () => {
  showCropper.value = false
  await nextTick()
  editorActive.value = 2
  showCropper.value = true
}

const stencilProps: any = ref({
  aspectRatio: 1
})

const filterEditor = ref({
  selectedBlend: 'normal',
  color: {
    red: 255,
    green: 255,
    blue: 172,
    alpha: 0
  },
  presets: [
      {
        'name': 'None',
        'blendMode': 'normal',
        'red': 0,
        'green': 0,
        'blue': 0,
        'alpha': 0
      },
      {
        'name': 'Nashville',
        'blendMode': 'multiply',
        'red': 255,
        'green': 255,
        'blue': 172,
        'alpha': 0
      },
      {
        'name': 'Lark',
        'blendMode': 'color-burn',
        'red': 226,
        'green': 211,
        'blue': 180,
        'alpha': 0
      },
      {
        'name': 'Oldies',
        'blendMode': 'hard-light',
        'red': 57,
        'green': 96,
        'blue': 25,
        'alpha': 0
      },
      {
        'name': 'Brownies',
        'blendMode': 'luminosity',
        'red': 193,
        'green': 118,
        'blue': 0,
        'alpha': 0
      },
      {
        'name': 'Fargo',
        'blendMode': 'screen',
        'red': 193,
        'green': 118,
        'blue': 0,
        'alpha': 0
      },
      {
        'name': 'Charcoal',
        'blendMode': 'luminosity',
        'red': 255,
        'green': 255,
        'blue': 255,
        'alpha': 0
      },
      {
        'name': 'Bagby',
        'blendMode': 'color-dodge',
        'red': 44,
        'green': 48,
        'blue': 70,
        'alpha': 0
      }   
    ]
})

const computedColor = computed(() => {
  return `rgb(${filterEditor.value.color.red}, ${filterEditor.value.color.green}, ${filterEditor.value.color.blue})`
})

const SelectPreset = (p: any) => {
  filterEditor.value.selectedBlend = p.blendMode
  filterEditor.value.color.red = p.red
  filterEditor.value.color.green = p.green
  filterEditor.value.color.blue = p.blue
}

const ImageDataResultProcess = () => {
  if (editorActive.value === 2) {
    router.go(-1)
    const { coordinates, canvas } = cropperRef.value.getResult()
    console.log(canvas.height, canvas.width, canvas)
    // this.coordinates = coordinates;
    // You able to do different manipulations at a canvas
    // but there we just get a cropped image
    const imgX = canvas.toDataURL()
    emit('save', imgX)
  } else if (editorActive.value === 1) {
    router.go(-1)
    const c = document.createElement('canvas')
    c.width = imageSelected.value.width
    c.height = imageSelected.value.height
    const ctx: any = c.getContext('2d')
    ctx.fillStyle = computedColor.value
    ctx.fillRect(0,0,imageSelected.value.width,imageSelected.value.height)
    ctx.globalCompositeOperation = filterEditor.value.selectedBlend
    ctx.drawImage(imageSelected.value, 0, 0, imageSelected.value.width, imageSelected.value.height)
    filterEditor.value.selectedBlend = 'normal'
    const imgX = c.toDataURL()
    emit('save', imgX)
  }
}

</script>

<template>
  <div v-show="props.show && OriginalImage" class="fixed z-[1001] top-0 left-0 h-full w-full bg-gray-50 dark:bg-black overflow-y-auto scroll-hidden" @click.stop>
    <div class="h-full w-full relative">
      <div class="mx-auto w-full md:w-[750px] max-w-[100%] bg-gray-400 dark:bg-gray-700 flex items-center relative" style="height: calc(100% - 84px);">
        <div class="relative w-full max-w-full max-h-full">
          <div v-show="editorActive === 1" class="relative block w-full">
            <img :src="OriginalImage || undefined" class="block w-full max-w-full">
            <div :style="[
               { backgroundBlendMode: filterEditor.selectedBlend },
               { backgroundColor: computedColor },
               { backgroundImage: `url(${OriginalImage})` }
              ]"
              style="background-size: cover;background-repeat:no-repeat;"
              class="absolute top-0 left-0 w-full h-full z-10"
            />
          </div>
          <div v-if="showCropper && OriginalImage" v-show="editorActive === 2" class="w-full max-w-full max-h-full">
            <cropper
              ref="cropperRef"
              :stencil-props="stencilProps"
              :src="OriginalImage"
              :resize-image="{
                adjustStencil: false
              }"
            />
          </div>
        </div>
        <div class="flex flex-col items-center justify-center fixed bottom-0 left-0 w-full z-10 text-gray-200">
          <div class="flex flex-col w-full md:w-[750px] max-w-[100%]">
            <div class="w-full flex justify-between">
              <div class="inline-flex w-full items-center justify-start pl-4 text-xs pb-2 text-center">
                <div class="font-bold px-4 w-20 py-1 rounded-full cursor-pointer text-red-600 bg-gray-200" @click.prevent="$router.go(-1)">
                  CANCEL
                </div>
              </div>
              <div class="inline-flex w-full items-center justify-center text-xs pb-2 text-center">
                <div :class="[editorActive === 1 ? 'border-white' : 'border-gray-400']" class="border-2 font-bold px-4 w-20 py-1 mr-1 rounded-full cursor-pointer" @click.prevent="editorActive = 1">
                  FILTER
                </div>
                <div :class="[editorActive === 2 ? 'border-white' : 'border-gray-400']" class="border-2 font-bold px-4 w-20 py-1 ml-1 rounded-full cursor-pointer" @click.prevent="ShowCropperWindow()">
                  ANGLE
                </div>
              </div>
              <div class="inline-flex w-full items-center justify-end pr-4 text-xs pb-2 text-center">
                <div class="font-bold px-4 w-20 py-1 rounded-full cursor-pointer text-teal-600 bg-gray-200" @click.prevent="ImageDataResultProcess()">
                  APPLY
                </div>
              </div>
            </div>
            <div v-if="editorActive === 1" class="relative whitespace-nowrap block text-center w-full items-center justify-center text-xs h-[84px] max-w-full overflow-x-auto bg-black scroll-hidden">
              <div
                v-for="preset in filterEditor.presets"
                class="relative inline-block h-20 w-20 my-1 mx-1 cursor-pointer"
                style="background-size: cover;background-repeat:no-repeat;"
                :style="{ backgroundImage: `url(${OriginalImage})`, backgroundBlendMode: preset.blendMode, backgroundColor: `rgb(${preset.red}, ${preset.green}, ${preset.blue}` }"
                @click.prevent="SelectPreset(preset)"
              />
            </div>
            <div v-else-if="editorActive === 2" class="relative block text-center w-full items-center justify-center text-xs h-[84px] max-w-full overflow-x-auto bg-black">
              <div class="p-1 inline-flex items-center justify-center h-full w-16 cursor-pointer" @click="stencilProps.aspectRatio ? (stencilProps.aspectRatio = null) : (stencilProps.aspectRatio = 1)">
                <svg v-if="stencilProps.aspectRatio" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-blue-400">
                  <path fill-rule="evenodd" d="M12 1.5a5.25 5.25 0 0 0-5.25 5.25v3a3 3 0 0 0-3 3v6.75a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3v-6.75a3 3 0 0 0-3-3v-3c0-2.9-2.35-5.25-5.25-5.25Zm3.75 8.25v-3a3.75 3.75 0 1 0-7.5 0v3h7.5Z" clip-rule="evenodd" />
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-gray-400">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                </svg>
              </div>
              <div class="p-1 inline-flex items-center justify-center h-full w-16 cursor-pointer" @click="flip(true, false)">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-white">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
                </svg>
              </div>
              <div class="p-1 inline-flex items-center justify-center h-full w-16 cursor-pointer" @click="flip(false, true)">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-white">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M3 7.5 7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5" />
                </svg>
              </div>
              <div class="p-1 inline-flex items-center justify-center h-full w-16 cursor-pointer" @click="rotate(90)">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-white">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m9 9 6-6m0 0 6 6m-6-6v12a6 6 0 0 1-12 0v-3" />
                </svg>
              </div>
              <div class="p-1 inline-flex items-center justify-center h-full w-16 cursor-pointer" @click="rotate(-90)">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-white">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m15 15-6 6m0 0-6-6m6 6V9a6 6 0 0 1 12 0v3" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>