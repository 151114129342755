<script setup lang="ts">
const { $JsonRaw, $Chat, $prompt }: any = useNuxtApp()
const { auth } = useAuth()
const route: any = useRoute()
const router = useRouter()
const { roomShow, setRoomShow }: any = useRoom()
const { ChatRoomsGet }: any = useChat()
const { TimelineData, TimelineGet } = useTimeline()
const { channelMapSelected, setChannelMapSelected, ChannelData, ChannelGet } = useChannel()

const TabChannel = ref('about')
watch(() => channelMapSelected.value, (v: any) => {
  if (v && v.selected) {
    ChannelGet(`map-${v.selected}`, `?pm=${v.selected}`)
    if (screen.width > 450) {
      if (route?.name === 'maps') {
        router.push('?room=1')
      }
    } else if (parseInt(route.query.room)) {
      router.go(-1)
    }
  } else {
    setRoomShow(false)
  }
}, { deep: true })
watch(() => roomShow.value, (v: boolean) => {
  if (v && !channelMapSelected.value?.selected) {
    ChannelGet('me', '?me=1')
  }
})

watch(() => TabChannel.value, (v: string) => {
  if (v && v === 'stream' && channelMapSelected.value?.selected && channelMapSelected.value?.selected !== 'all') {
    TimelineGet(channelMapSelected.value.selected, `?channel=${channelMapSelected.value.selected}`)
  }
}, { immediate: true })

const ChannelActionFollow = async (channelId: string, isActive?: boolean, isPrivate?: number) => {
  const c = await $prompt({
    title: isPrivate ? 'Ask to Join' : 'Confirm',
    description: isActive
      ? 'Leave this channel?'
      : isPrivate ? `This is a private channel, please fill out the form below correctly. The channel administrator will review your request to join.` : `Join this channel!`,
    form: isActive
      ? null
      : isPrivate ? {
          fields: [
            {
              title: 'Search Sub-District (Desa/Kelurahan) :',
              type: 'subdistrict'
            },
            {
              title: 'Add notes for administrator :',
              type: 'notes'
            }
          ]
        } : null,
    act: {
      reverse: true
    }
  })

  let payloads: any = { null: 'null' }
  if (!c) {
    return false
  } else if (c.response) {
    payloads = c.form?.fields || { null: 'null' }
  }
  channelMapSelected.value[channelMapSelected.value.selected].me_is_follower = isActive ? false : true
  const r = await useApiPost(`/channel/${channelId}/follow`, payloads)
  if (r && r.status) {
    if (r.data === 'me_is_follower') {
      channelMapSelected.value[channelMapSelected.value.selected].count_followers = parseInt(channelMapSelected.value[channelMapSelected.value.selected].count_followers) + 1
      $Chat.roomJoin(`1:${channelId}`)
      ChatRoomsGet()
    } else if (r.data === 'unfollowed') {
      channelMapSelected.value[channelMapSelected.value.selected].count_followers = parseInt(channelMapSelected.value[channelMapSelected.value.selected].count_followers) - 1
      $Chat.roomLeave(`1:${channelId}`)
    } else {
      return false
    }
    channelMapSelected.value[channelMapSelected.value.selected].me_is_follower = r.data === 'me_is_follower'
    ChannelGet('me', '?me=1')
  }
}

</script>
<template>
  <div
    :class="[roomShow && route.name === 'maps' ? 'left-0 md:left-[64px]' : '-left-[768px] md:-left-96']"
    class="fixed transition-[left] duration-150 pt-[60px] shadow text-gray-900 dark:text-gray-200 top-0 h-full w-full md:w-[357px] max-w-full bg-gray-50 dark:bg-midnight-10 z-[499]"
  >
    <span v-show="route.name === 'maps'" class="flex items-center justify-center w-11 h-11 rounded-3xl bg-gray-50 dark:bg-midnight-10 shadow fixed top-4 right-4 md:left-[436px] cursor-pointer" @click.prevent="roomShow ? router.back() : router.push('?room=1')">
      <Squares2X2Icon v-if="!roomShow" class="size-5" />
      <XMarkIcon v-else class="size-6" />
    </span>
    <div v-if="!channelMapSelected.selected || channelMapSelected.selected === 'all'" class="w-full px-4">
      <div class="flex items-center justify-between py-4 px-2">
        <div class="flex items-center font-bold text-xs">
          <SquaresPlusIcon class="w-4 h-4 mr-2" />
          My Channel
        </div>
        <div
          class="flex items-center justify-center text-xs px-3 py-1 rounded-3xl border bg-gray-900 dark:bg-gray-100 text-gray-200 dark:text-gray-900 cursor-pointer"
          @click.prevent="router.push('?channel=1')"
        >
          + New Channel
        </div>
      </div>
      <TransitionGroup v-if="ChannelData?.me?.data" name="list" tag="div" class="warloc-slide-group warloc-sidebar-channel py-2 rounded-lg overflow-auto max-h-[calc(100vh-115px)] scroll-hidden">
        <div v-if="!ChannelData?.me?.data.length" class="mx-auto text-center p-4 text-gray-400 text-[12px]">
          - no data -
        </div>
        <div
          v-for="(cm, iCm) in ChannelData?.me?.data"
          :key="'channel-me-' + iCm"
          class="flex items-center text-sm px-3 py-2 rounded bg-gray-100 dark:bg-gray-900 hover:bg-gray-200 dark:hover:bg-gray-800 cursor-pointer mb-1 shadow-xs"
          @click.prevent="setChannelMapSelected(cm.id, $JsonRaw(cm)), setChannelMapSelected('selected', cm.id)"
        >
          <div class="bg-cover-center bg-gray-200 dark:bg-gray-800 w-10 h-10 rounded-full" :style="{ backgroundImage: `url(${cm.logo})` }" />
          <div class="flex flex-col items-start px-4 max-w-[calc(100%-64px)]">
            <strong class="text-sm">{{ cm.name }}</strong>
            <small class="text-gray-400 line-clamp-2 max-w-full text-wrap leading-4">
              {{ cm.description || '-' }}
            </small>
          </div>
          <div class="grow" />
          <ChevronRightIcon class="w-5" />
        </div>
      </TransitionGroup>
    </div>
    <div v-else class="relative w-full">
      <div class="w-full flex items-center px-0 pt-2">
        <div class="w-[50%] flex items-center">
          <span :class="[TabChannel === 'about' ? 'border-gray-400 dark:border-gray-400 text-black dark:text-white' : 'text-gray-400 border-transparent']" class="border-b-2 w-[50%] text-[13px] px-4 py-2 text-center cursor-pointer" @click.prevent="TabChannel = 'about'">
            about
          </span>
          <span :class="[TabChannel === 'event' ? 'border-gray-400 dark:border-gray-400 text-black dark:text-white' : 'text-gray-400 border-transparent']" class="border-b-2 w-[50%] text-[13px] px-4 py-2 text-center cursor-pointer" @click.prevent="TabChannel = 'event'">
            #event
          </span>
        </div>
        <div class="w-[50%] flex items-center justify-end">
          <!-- <span :class="[TabChannel === 'network' ? 'border-gray-400 dark:border-gray-400 text-black dark:text-white' : 'text-gray-400 border-transparent']" class="border-b-2 w-[50%] text-[13px] px-4 py-2 text-center cursor-pointer" @click.prevent="TabChannel = 'network'">
            network
          </span> -->
          <span :class="[TabChannel === 'stream' ? 'border-gray-400 dark:border-gray-400 text-black dark:text-white' : 'text-gray-400 border-transparent']" class="border-b-2 w-[50%] text-[13px] px-4 py-2 text-center cursor-pointer" @click.prevent="TabChannel = 'stream'">
            #stream
          </span>
        </div>
      </div>
      <div class="h-px w-full bg-gray-200 dark:bg-gray-800" />
      <div v-if="TabChannel === 'stream'" class="relative grow">
        <div class="h-[calc(100vh-160px)] w-full overflow-x-auto">
        </div>
        <div class="h-[48px] dark:bg-black flex items-center">
          <div class="flex items-center justify-center h-[48px] w-[48px] bg-gray-100 dark:bg-gray-900 cursor-pointer" @click.prevent="router.push('?post=1')">
            <PlusIcon class="w-5 h-5" />
          </div>
          <input type="text" class="grow py-2 px-4 bg-transparent outline-none" rows="1" spellcheck="false" placeholder="Write stream post">
        </div>
        <!-- <div v-if="TimelineData[channelMapSelected.selected] && !TimelineData[channelMapSelected.selected].data?.length" class="text-center p-4 text-gray-200">
          no data
        </div> -->
        <!-- <div class="flex w-full justify-between items-center py-2">
          <span class="text-xs text-gray-400">
            # post stream
          </span>
          <div
            v-if="channelMapSelected[channelMapSelected.selected].creator === auth?.id"
            class="text-xs rounded-3xl text-gray-800 dark:text-gray-200 cursor-pointer"
            @click.prevent="router.push('?post=1')"
          >
            + new post
          </div>
        </div>
        <TimelineList v-if="TimelineData[channelMapSelected.selected]" :key-index="channelMapSelected.selected" class="pt-4" /> -->
      </div>
      <div v-if="TabChannel === 'about'" class="relative max-h-[calc(100vh-111px)] overflow-auto scrollbar-mini px-2">
        <div class="flex items-center justify-center flex-col text-center pt-8">
          <div class="relative">
            <span
              class="inline-block w-16 h-16 lg:w-20 lg:h-20 border-2 lg:border-4 border-white shadow-inset rounded-full bg-cover"
              :style="{ backgroundImage: 'url(' + (channelMapSelected[channelMapSelected.selected].logo || '') + ')' }"
            />
            <nuxt-link
              v-if="auth && auth.id === channelMapSelected[channelMapSelected.selected].creator"
              to="?channel=1"
              class="absolute -bottom-1 -right-1 z-10 cursor-pointer"
            >
              <PencilSquareIcon class="size-5 text-gray-400" />
            </nuxt-link>
          </div>
          <div class="flex flex-col items-center justify-center w-full font-sans">
            <h1 class="inline-flex flex-col justify-center items-center pt-3 lg:pt-6 font-bold tracking-wide text-md md:text-lg lg:text-xl truncate w-full text-black dark:text-white">
              {{ channelMapSelected[channelMapSelected.selected].name }}
            </h1>
            <h2 class="w-[300px] max-w-[90%] text-xs lg:text-sm text-center pt-1 pb-3 text-gray-400 dark:text-gray-400">
              {{ channelMapSelected[channelMapSelected.selected].description || '-' }}
            </h2>
            <div class="flex items-center text-xs">
              {{ parseInt(channelMapSelected[channelMapSelected.selected].count_followers) }} Member{{ parseInt(channelMapSelected[channelMapSelected.selected].count_followers) ? 's' : '' }}
              <span v-if="auth?.id !== channelMapSelected[channelMapSelected.selected].parent_master && !channelMapSelected[channelMapSelected.selected].parent_master && !channelMapSelected[channelMapSelected.selected].parent_channel" class="ml-4 rounded-xl py-1 px-4 bg-black text-white dark:bg-white dark:text-black hover:bg-gray-800 dark:hover:bg-gray-200 text-xs cursor-pointer" @click.prevent="ChannelActionFollow(channelMapSelected[channelMapSelected.selected].id, channelMapSelected[channelMapSelected.selected].me_is_follower, parseInt(channelMapSelected[channelMapSelected.selected].channel_mode))">
                {{ channelMapSelected[channelMapSelected.selected].me_is_follower ? 'Leave' : (parseInt(channelMapSelected[channelMapSelected.selected].channel_mode) ? '+ Ask to Join' : '+ Join') }}
              </span>
            </div>
          </div>
          <div v-if="false" class="flex w-full flex-col justify-center text-md text-center pt-4">
            <div class="w-full flex items-center justify-center">
              <div class="flex flex-col justify-center items-center text-gray-500 dark:text-gray-400 w-[90px] lg:w-[100px]">
                <strong class="text-black dark:text-white">{{ channelMapSelected[channelMapSelected.selected].count_followers || 0 }}</strong>
                <span class="text-xs">Member{{ parseInt(channelMapSelected[channelMapSelected.selected].count_followers) ? 's' : '' }}</span>
              </div>
              <div class="flex flex-col justify-center items-center text-gray-500 dark:text-gray-400 w-[90px] lg:w-[100px]">
                <strong class="text-black dark:text-white">{{ channelMapSelected[channelMapSelected.selected].count_posts || 0 }}</strong>
                <span class="text-xs">Post{{ parseInt(channelMapSelected[channelMapSelected.selected].count_posts) ? 's' : '' }}</span>
              </div>
              <div class="flex flex-col justify-center items-center text-gray-500 dark:text-gray-400 w-[90px] lg:w-[100px]">
                <strong class="text-black dark:text-white">{{ channelMapSelected[channelMapSelected.selected].count_members || 0 }}</strong>
                <span class="text-xs">Member</span>
              </div>
            </div>
          </div>
          <div class="h-px w-full bg-gray-200 dark:bg-gray-700 mt-4"></div>
          <div class="w-full">
            <!-- <div v-if="auth && auth.id === channelMapSelected[channelMapSelected.selected].creator" class="text-center py-4 px-4 text-sm">
              <span class="cursor-pointer font-weight-bold" @click.prevent="router.push('?channel=1&sub=1')">
                + sub channel
              </span>
            </div> -->
            <div v-if="channelMapSelected.selected && ChannelData[`map-${channelMapSelected.selected}`]" class="px-2 py-4 rounded-lg overflow-auto" style="height: calc(100% - 24px)">
              <div v-if="!ChannelData[`map-${channelMapSelected.selected}`].data.length" class="mx-auto text-center p-4 text-gray-400 text-[12px]">
                - no data -
              </div>
              <div
                v-for="(cm, iCm) in ChannelData[`map-${channelMapSelected.selected}`].data"
                :key="'channel-sub-map-' + iCm"
                class="flex items-center text-sm px-3 py-2 rounded-lg border border-gray-700 dark:border-gray-400 hover:bg-gray-100 dark:hover:bg-gray-900 cursor-pointer mb-2 shadow-xs"
                @click.prevent="setChannelMapSelected(cm.id, $JsonRaw(cm)), setChannelMapSelected('selected', cm.id)"
              >
                <div class="bg-cover-center bg-gray-400 w-7 h-7 rounded-full mr-2" :style="{ backgroundImage: `url(${cm.logo})` }" />
                {{ cm.name }}
                <div class="grow" />
                <ChevronRightIcon class="size-4" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="TabChannel === 'network'" class="">
        <div v-if="channelMapSelected.selected && ChannelData[`map-${channelMapSelected.selected}`]" class="px-1 py-4 rounded-lg overflow-auto" style="height: calc(100% - 24px)">
          <div v-if="!ChannelData[`map-${channelMapSelected.selected}`].data.length" class="mx-auto text-center p-4 text-gray-400 text-[12px]">
            - no data -
          </div>
          <div
            v-for="(cm, iCm) in ChannelData[`map-${channelMapSelected.selected}`].data"
            :key="'channel-map-' + iCm"
            class="flex items-center text-sm p-4 rounded-lg bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 cursor-pointer mb-2 shadow-xs"
          >
            <div class="bg-cover-center bg-gray-400 w-7 h-7 rounded-full mr-2" :style="{ backgroundImage: `url(${cm.logo})` }" />
            {{ cm.name }}
            <div class="grow" />
            <ChevronRightIcon class="size-4" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.warloc-sidebar-channel {
  .list-enter-active,
  .list-leave-active {
    transition: all 0.05s ease-in-out;
  }
  .list-enter-from,
  .list-leave-to {
    opacity: 0;
    transform: translateY(-30px);
  }
  a {
    &.router-link-exact-active {
      background-color: #f0f0f0;
    }
  }
}
</style>