<script setup lang="ts">
// https://github.com/infinitered/nsfwjs IMAGE SENSOR
const { $parseHTML, $toBase64, $JsonRaw, $convertImage, $blobToBase64, $dataURItoBlob, $ImagesOne }: any = useNuxtApp()
const { channelDetail, channelMapSelected, showCreateChannel, setShowCreateChannel }: any = useChannel()
const { ChatRoomsGet }: any = useChat()
const router: any = useRouter()
const route: any = useRoute()
const emit = defineEmits()

const channelLoading = ref(false)
const ChannelPayloadTemp = {
  channel_mode: 0,
  channel_mode_kyc: 0,
  parent_master: '',
  parent_channel: '',
  name: null,
  description: null,
  type: 'ngo',
  typesub: 'community',
  coverage: '0',
  location_id: null,
  location_lat: null,
  location_lng: null,
  logo: null
}
const ChannelPayload: any = ref({
  channel_mode: 0,
  channel_mode_kyc: 0,
  parent_master: '',
  parent_channel: '',
  name: null,
  description: null,
  type: 'ngo',
  typesub: 'community',
  coverage: '0',
  location_id: null,
  location_lat: null,
  location_lng: null,
  logo: null
})

const ChannelLocationSelected: any = ref({
  show: false,
  data: { lat: null, lng: null }
})
const ChannelLocationSelectedUpdate = (v: any) => {
  ChannelPayload.value.location_lat = v.lat
  ChannelPayload.value.location_lng = v.lng
}

watch(() => showCreateChannel.value, (v: any) => {
  if (v) {
    if (channelMapSelected.value[channelMapSelected.value.selected] && route.name === 'maps') {
      if (parseInt(route.query?.sub)) {
        ChannelLocationSelected.value.data.lat = null
        ChannelLocationSelected.value.data.lng = null
        ChannelLocationSelected.value.show = true
        const c = $JsonRaw(ChannelPayloadTemp)
        c.parent_master = channelMapSelected.value.selected
        c.parent_channel = channelMapSelected.value.selected
        ChannelPayload.value = c
      } else {
        const c = channelMapSelected.value[channelMapSelected.value.selected]
        ChannelPayload.value.id = c.id || null
        ChannelPayload.value.channel_mode = parseInt(c.channel_mode) || 0
        ChannelPayload.value.channel_mode_kyc = parseInt(c.channel_mode_kyc) || 0
        ChannelPayload.value.parent_master = c.parent_master || null
        ChannelPayload.value.parent_channel = c.parent_channel || null
        ChannelPayload.value.id = c.id || null
        ChannelPayload.value.name = c.name
        ChannelPayload.value.description = c.description
        ChannelPayload.value.type = c.type
        ChannelPayload.value.typesub = c.typesub
        ChannelPayload.value.coverage = c.coverage
        ChannelPayload.value.location_id = c.location_id
        ChannelPayload.value.location_lat = parseFloat(c.location_lat) || 0
        ChannelPayload.value.location_lng = parseFloat(c.location_lng) || 0
        ChannelPayload.value.logo = c.logo
        ChannelPayload.value.channel_mode = parseInt(c.channel_mode) || 0
        ChannelLocationSelected.value.data.lat = parseFloat(c.location_lat) || -3.0150618
        ChannelLocationSelected.value.data.lng = parseFloat(c.location_lng) || 119.5509895
        if (parseFloat(c.location_lat) && parseFloat(c.location_lng)) {
          ChannelLocationSelected.value.show = true
        }
      }
    } else if (channelDetail.value[route.params.channelid] && route.name === 'channel-channelid') {
      const c = channelDetail.value[route.params.channelid]
      ChannelPayload.value.id = c.id || null
      ChannelPayload.value.channel_mode = parseInt(c.channel_mode) || 0
      ChannelPayload.value.channel_mode_kyc = parseInt(c.channel_mode_kyc) || 0
      ChannelPayload.value.parent_master = c.parent_master || null
      ChannelPayload.value.parent_channel = c.parent_channel || null
      ChannelPayload.value.id = c.id || null
      ChannelPayload.value.name = c.name
      ChannelPayload.value.description = c.description
      ChannelPayload.value.type = c.type
      ChannelPayload.value.typesub = c.typesub
      ChannelPayload.value.coverage = c.coverage
      ChannelPayload.value.location_id = c.location_id
      ChannelPayload.value.location_lat = parseFloat(c.location_lat) || 0
      ChannelPayload.value.location_lng = parseFloat(c.location_lng) || 0
      ChannelPayload.value.logo = c.logo
      ChannelPayload.value.channel_mode = parseInt(c.channel_mode) || 0
      ChannelLocationSelected.value.data.lat = parseFloat(c.location_lat) || -3.0150618
      ChannelLocationSelected.value.data.lng = parseFloat(c.location_lng) || 119.5509895
      if (parseFloat(c.location_lat) && parseFloat(c.location_lng)) {
        ChannelLocationSelected.value.show = true
      }
    } else {
      ChannelLocationSelected.value.data.lat = null
      ChannelLocationSelected.value.data.lng = null
      ChannelLocationSelected.value.show = false
      ChannelPayload.value = $JsonRaw(ChannelPayloadTemp)
    }
  }
}, { immediate: true })

const UploadImage: any = () => {
  if (channelLoading.value) {
    return
  }
  let u = document.getElementById('file-uploader')
  if (u) {
    u.remove()
  }
  setTimeout(() => {
    u = document.createElement('input')
    u.setAttribute('type', 'file')
    u.setAttribute('id', 'file-uploader')
    u.setAttribute('class', 'file-uploader')
    // u.setAttribute('multiple', 'file-uploader')
    u.setAttribute('accept', '.png,.jpeg,.jpg')
    u.addEventListener('change', async (e: any) => {
      const result = await $convertImage(e.target.files[0], 350, 350)
      ChannelPayload.value.logo = await $blobToBase64(result)
    })
    u.click()
  }, 100)
}

const ChannelProcess = async () => {
  if (channelLoading.value) {
    return
  }
  const file = $dataURItoBlob(ChannelPayload.value.logo)
  const d: any = new FormData()
  if (ChannelPayload.value.id) {
    d.append('id', ChannelPayload.value.id)
  }
  d.append('channel_mode', ChannelPayload.value.channel_mode || 0)
  d.append('channel_mode_kyc', ChannelPayload.value.channel_mode_kyc || 0)
  d.append('file', file)
  d.append('logo', ChannelPayload.value.logo || '')
  d.append('name', ChannelPayload.value.name || '')
  d.append('parent_master', ChannelPayload.value.parent_master || '')
  d.append('parent_channel', ChannelPayload.value.parent_channel || '')
  d.append('type', ChannelPayload.value.type || '')
  d.append('typesub', ChannelPayload.value.typesub || '')
  d.append('coverage', ChannelPayload.value.coverage || '')
  d.append('location_id', ChannelPayload.value.location_id || 0)
  d.append('location_lat', (ChannelLocationSelected.value.show && parseFloat(ChannelPayload.value.location_lat)) ? parseFloat(ChannelPayload.value.location_lat) : 0)
  d.append('location_lng', (ChannelLocationSelected.value.show && parseFloat(ChannelPayload.value.location_lng)) ? parseFloat(ChannelPayload.value.location_lng) : 0)
  d.append('description', ChannelPayload.value.description || '')
  channelLoading.value = true
  const req = await useApiPost('/channel', d)
  if (req && req.status && req.data && req.data.id) {
    ChannelPayload.value.name = null
    ChannelPayload.value.description = null
    ChannelPayload.value.type = 'ngo'
    ChannelPayload.value.coverage = ''
    ChannelPayload.value.logo = null
    emit('reload', true)
    router.go(-1)
    ChatRoomsGet()
  }
  channelLoading.value = false
}

</script>

<template>
  <div v-show="showCreateChannel" id="channel-create" class="fixed z-[501] top-0 left-0 h-full flex items-center w-full bg-[#FFFFFFFE] dark:bg-[#000000EE] md:px-4" @click.prevent="$router.back()">
    <div class="w-full md:pb-0 relative h-full flex items-center">
      <div class="flex flex-col justify-end mx-auto my-auto w-full md:w-[600px] max-w-[100%] bg-gray-100 dark:bg-midnight-10 max-h-full overflow-y-auto scroll-hidden" @click.stop>
        <div class="overflow-auto scroll-hidden px-4 pb-4 pt-8">
          <div class="flex justify-center items-center px-4 py-6 mb-6 w-full">
            <div :class="[ChannelPayload.logo ? 'border' : 'border-dashed']" class="bg-cover-center overflow-hidden flex justify-center items-center w-24 h-24 rounded-full border-[3px] border-gray-300 dark:border-gray-500 text-gray-400 cursor-pointer" :style="{ backgroundImage: `url(${ChannelPayload.logo || ''})` }" @click.prevent="UploadImage">
              {{ ChannelPayload.logo ? '' : 'Logo' }}
            </div>
          </div>
          <div class="h-px bg-gray-200 dark:bg-gray-700 mb-8" />
          <div v-if="ChannelPayload.parent_master" class="md:flex md:items-center mb-6 w-full">
            <div class="md:w-1/3">
              <label class="block text-gray-500 md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                Master Channel
              </label>
            </div>
            <div v-if="channelMapSelected && channelMapSelected[channelMapSelected.selected]" class="flex items-center justify-end md:w-2/3 text-sm text-gray-400 font-bold">
              <div class="truncate" style="max-width:calc(100% - 52px);">
                {{ channelMapSelected[channelMapSelected.selected].name }}
              </div>
              <div class="bg-cover-center bg-gray-400 w-7 h-7 rounded-full ml-2" :style="{ backgroundImage: `url(${channelMapSelected[channelMapSelected.selected].logo})` }" />
            </div>
          </div>
          <div v-if="ChannelPayload.parent_master && ChannelPayload.parent_channel && ChannelPayload.parent_master !== ChannelPayload.parent_channel" class="md:flex md:items-center mb-6 w-full">
            <div class="md:w-1/3">
              <label class="block text-gray-500 md:text-right mb-1 md:mb-0 pr-4" for="channel-parent">
                Parent Channel
              </label>
            </div>
            <div class="md:w-2/3">
              <input v-model="ChannelPayload.parent_channel" placeholder="Channel parent" class="bg-gray-200 dark:bg-gray-900 appearance-none border-2 border-gray-200 dark:border-gray-800 rounded w-full py-3 px-4 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none" id="channel-parent" type="text">
            </div>
          </div>
          <div class="md:flex md:items-center mb-6 w-full">
            <div class="md:w-1/3">
              <label class="block text-gray-500 md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                Channel Name
              </label>
            </div>
            <div class="md:w-2/3">
              <input v-model="ChannelPayload.name" placeholder="Channel name" class="bg-gray-200 dark:bg-gray-900 appearance-none border-2 border-gray-200 dark:border-gray-800 rounded w-full py-3 px-4 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none" id="inline-full-name" type="text">
            </div>
          </div>
          <div class="md:flex md:items-center mb-6 w-full">
            <div class="md:w-1/3">
              <label class="block text-gray-500 md:text-right mb-1 md:mb-0 pr-4" for="channel-description">
                Channel Description
              </label>
            </div>
            <div class="md:w-2/3">
              <input v-model="ChannelPayload.description" placeholder="Channel description" class="bg-gray-200 dark:bg-gray-900 appearance-none border-2 border-gray-200 dark:border-gray-800 rounded w-full py-3 px-4 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none" id="channel-description" type="text">
            </div>
          </div>
          <div class="md:flex md:items-center mb-6 w-full">
            <div class="md:w-1/3">
              <label class="block text-gray-500 md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                Is Private Channel ?
              </label>
            </div>
            <div class="md:w-2/3 relative">
              <select v-model.number="ChannelPayload.channel_mode" placeholder="Fixed location" class="cursor-pointer bg-gray-200 dark:bg-gray-900 appearance-none border-2 border-gray-200 dark:border-gray-800 rounded w-full py-3 px-4 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none">
                <option :value="0">No</option>
                <option :value="1">Yes</option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
              </div>
            </div>
          </div>
          <div class="h-px bg-gray-200 dark:bg-gray-700 mb-6" />
          <!-- <div class="md:flex md:items-center mb-6 w-full">
            <div class="md:w-1/3">
              <label class="block text-gray-500 md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                Channel Type
              </label>
            </div>
            <div class="md:w-2/3 relative">
              <select v-model="ChannelPayload.type" placeholder="Channel type" class="cursor-pointer bg-gray-200 dark:bg-gray-900 appearance-none border-2 border-gray-200 dark:border-gray-800 rounded w-full py-3 px-4 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none" @change="ChannelPayload.type === 'gov' ? ChannelPayload.typesub = 'executive' : ChannelPayload.typesub = 'community'">
                <option value="ngo">Non Governmental Organization</option>
                <option value="gov">Government</option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
              </div>
            </div>
          </div> -->
          <div v-if="ChannelPayload.type === 'gov'" class="md:flex md:items-center mb-6 w-full">
            <div class="md:w-1/3">
              <label class="block text-gray-500 md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                Channel Category
              </label>
            </div>
            <div class="md:w-2/3 relative">
              <select v-model="ChannelPayload.typesub" placeholder="Channel type" class="cursor-pointer bg-gray-200 dark:bg-gray-900 appearance-none border-2 border-gray-200 dark:border-gray-800 rounded w-full py-3 px-4 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none">
                <option value="executive">Executive</option>
                <option value="legislative">Legislative</option>
                <option value="judicial">Judicial</option>
                <option value="''">Other</option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
              </div>
            </div>
          </div>
          <div v-else-if="ChannelPayload.type === 'ngo'" class="md:flex md:items-center mb-6 w-full">
            <div class="md:w-1/3">
              <label class="block text-gray-500 md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                Channel Category
              </label>
            </div>
            <div class="md:w-2/3 relative">
              <select v-model="ChannelPayload.typesub" placeholder="Channel type" class="cursor-pointer bg-gray-200 dark:bg-gray-900 appearance-none border-2 border-gray-200 dark:border-gray-800 rounded w-full py-3 px-4 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none">
                <option value="community">Community/Group</option>
                <option value="business">Business</option>
                <option value="education">Education (School/College)</option>
                <option value="health">Health</option>
                <option value="political-party">Political Party</option>
                <option value="foundation">Foundation (Non-Profit)</option>
                <option value="cooperative">Cooperative (Koperasi)</option>
                <option value="multi-cooperative">Multi Cooperative (Koperasi Multipihak)</option>
                <!-- <option value="local">Local Group / RT / RW / Village</option> -->
                <option value="''">Other</option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
              </div>
            </div>
          </div>
          <div v-if="false" class="md:flex md:items-center mb-6 w-full">
            <div class="md:w-1/3">
              <label class="block text-gray-500 md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                Channel Coverage
              </label>
            </div>
            <div class="md:w-2/3 relative">
              <select v-model="ChannelPayload.coverage" placeholder="Channel coverage" class="cursor-pointer bg-gray-200 dark:bg-gray-900 appearance-none border-2 border-gray-200 dark:border-gray-800 rounded w-full py-3 px-4 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none">
                <option value="0">National</option>
                <option value="1">Province</option>
                <option value="2">City (Kota/Kabupaten)</option>
                <option value="3">District (Kecamatan)</option>
                <option value="4">Sub-District (Desa/Kelurahan)</option>
                <option value="5">RW (Rukun Warga)</option>
                <option value="6">RT (Rukun Tetangga)</option>
                <option value="7">House / Family</option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
              </div>
            </div>
          </div>
          <div v-if="ChannelPayload.coverage !== '0'" class="md:flex md:items-center mb-6 w-full">
            <div class="md:w-1/3">
              <label class="block text-gray-500 md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                <!-- Location -->
              </label>
            </div>
            <div class="md:w-2/3 relative">
              <input v-model.number="ChannelPayload.location_id" type="text" placeholder="Search ..." class="bg-gray-200 dark:bg-gray-900 appearance-none border-2 border-gray-200 dark:border-gray-800 rounded w-full py-3 px-4 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none">
            </div>
          </div>
          <div class="h-px bg-gray-200 dark:bg-gray-700 mb-6" />
          <div class="md:flex md:items-center mb-2 w-full">
            <div class="md:w-1/3">
              <label class="block text-gray-500 md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                Set Map Location :
              </label>
            </div>
            <div class="md:w-2/3 relative">
              <select v-model="ChannelLocationSelected.show" placeholder="Fixed location" class="cursor-pointer bg-gray-200 dark:bg-gray-900 appearance-none border-2 border-gray-200 dark:border-gray-800 rounded w-full py-3 px-4 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none">
                <option :value="true">Yes</option>
                <option :value="false">No</option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
              </div>
            </div>
          </div>
          <div v-if="ChannelLocationSelected.show" class="w-full h-[300px]">
            <WarlocMapSelect v-model="ChannelLocationSelected.data" :show="ChannelLocationSelected.show" @selected="ChannelLocationSelectedUpdate" />
          </div>
        </div>
        <div class="h-px bg-gray-200 dark:bg-gray-700" />
        <div class="flex w-full justify-between p-4">
          <div class="flex items-center justify-between px-4 py-2 text-red-500 dark:text-red-700 rounded font-serif font-bold text-sm cursor-pointer" @click.prevent="$router.go(-1)">
            Cancel
          </div>
          <div
            class="flex items-center justify-between px-6 py-2 rounded-3xl shadow-sm font-serif bg-gradient-to-r from-green-700 to-green-500 text-white text-sm cursor-pointer"
            @click.prevent="channelLoading ? false : ChannelProcess()"
          >
            <svg v-if="channelLoading" class="animate-spin -ml-1 mr-3 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            {{ channelLoading ? 'Processing ...' : ((ChannelPayload.id ? 'Update' : 'Create New') + ' Channel') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.warloc-editor-is-empty {
  .atwho-wrap {
    >.warloc-content-area {
      &::after {
        content: 'Write here ...';
        position: absolute;
        top:0px;
        left:20px;
        height:100%;
        display: flex;
        align-items: center;
        color: #aaa;
      }
    }
  }
}
</style>
