<script setup lang="ts">
const sWidth = ref(0)
if (process.client) {
  sWidth.value = screen.width
}
</script>
<template>
  <div class="relative w-full bg-gray-50">
    <div class="border-b border-gray-100 h-[60px] px-3 py-3">
      <input type="text" placeholder="Search" class="block w-full h-full outline-none py-2 px-4 rounded-md bg-gray-100 focus:bg-gray-200">
    </div>
    <div class="scrollbar-mini" style="height: calc(100dvh - 60px);overflow:auto;" @touchmove.stop>
      <div v-for="(i) in 30" class="flex items-center h-16 hover:bg-indigo-50 px-3 cursor-pointer" @click.prevent="$router.push('?chat=1'), sWidth <= 768 ? $emit('close', true) : false">
        <span class="w-[47px] h-[47px] rounded-full bg-gray-300 mr-3 px-2" />
        <div class="block leading-10" style="width: calc(100% - 60px);">
          <div class="text-md leading-5 mb-[2px]">
            Chatroom {{ i }}
          </div>
          <div class="text-xs text-gray-500 truncate tracking-normal">
            <span class="text-teal-500">New Message</span>: Lorem Ipsum dolor sit amet Lorem Ipsum dolor sit amet
          </div>
        </div>
      </div>
    </div>
  </div>
</template>