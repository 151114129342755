<template>
  <transition name="fade">
    <div
      v-if="isVisible"
      class="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-gradient-to-r from-green-500 to-green-700 text-white p-4 rounded-lg shadow-lg flex flex-col items-center w-full max-w-md z-[1000000000000]"
    >
      <p class="text-sm text-center">We use cookies to improve your experience. By accepting, you agree to our Privacy Policy.</p>
      
      <div class="mt-3 flex items-center space-x-4">
        <div class="flex items-center space-x-2">
          <label for="doNotShow" class="text-sm cursor-pointer">Don't show again</label>
          <input
            id="doNotShow"
            type="checkbox"
            v-model="doNotShowAgain"
            class="w-5 h-5 text-green-500 border-green-700 rounded focus:ring-0 cursor-pointer"
          />
        </div>
        <button
          @click="acceptCookies"
          class="px-4 py-2 bg-green-500 hover:bg-green-600 text-white rounded-full text-sm transition"
        >
          Accept
        </button>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const isVisible = ref(true);
const doNotShowAgain = ref(false);

onMounted(() => {
  // Check if the "do not show again" option was previously set in localStorage
  if (localStorage.getItem('acceptCookies') === 'false') {
    isVisible.value = false;
  }
});

function acceptCookies() {
  // Save user preference in localStorage
  if (doNotShowAgain.value) {
    localStorage.setItem('acceptCookies', 'false');
  }
  isVisible.value = false;
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
